
import React, { useRef, useState } from "react";
// Import Swiper React components
function Stories() {
  

  return (
    <div className="App">

        {/* <!-- Page banner Section Start --> */}
        <div class="section page-banner-section contact-back-img" >
            <div class="container">

                {/* <!-- Page banner Section Start --> */}
                <div class="page-banner-content">
                    <h2 class="page-title">Success Stories</h2>

                    <ul class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item active">Success Stories</li>
                    </ul>
                </div>
                {/* <!-- Page banner Section End --> */}

            </div>
        </div>
        {/* <!-- Page banner Section End --> */}
 
 {/* <!-- Blog Section End --> */}
        <div class="section section-padding-02 section-padding">
            <div class="container">

                {/* <!-- Section Title Start --> */}
                <div class="section-title">
                    <h6 class="sub-title">Client Success Stories</h6>
                    <h2 class="main-title">Latest Article of Nutrition</h2>
                </div>
                {/* <!-- Section Title End --> */}

                {/* <!-- Blog Wrapper Start --> */}
                <div class="blog-wrapper">
                    <div class="row gx-xxl-3">

                       
                    <div class="row">
  <img src="/assets/images/stories/01.jpeg" alt="" className="col-lg-4 zoom-stories" style={{marginBottom : 25}}/>
  <img src="/assets/images/stories/02.jpeg" alt="" className="col-lg-4 zoom-stories" style={{marginBottom : 25}}/>
  <img src="/assets/images/stories/03.jpeg" alt="" className="col-lg-4 zoom-stories" style={{marginBottom : 25}}/>
  <img src="/assets/images/stories/04.jpeg" alt="" className="col-lg-4 zoom-stories" style={{marginBottom : 25}}/>
  <img src="/assets/images/stories/05.jpeg" alt="" className="col-lg-4 zoom-stories" style={{marginBottom : 25}}/>
  <img src="/assets/images/stories/06.jpeg" alt="" className="col-lg-4 zoom-stories" style={{marginBottom : 25}}/>
  <img src="/assets/images/stories/07.jpeg" alt="" className="col-lg-4 zoom-stories"style={{marginBottom : 25}}/>
  <img src="/assets/images/stories/08.jpeg" alt="" className="col-lg-4 zoom-stories" style={{marginBottom : 25}}/>
  <img src="/assets/images/stories/09.jpeg" alt="" className="col-lg-4 zoom-stories" style={{marginBottom : 25}}/>
  <img src="/assets/images/stories/10.jpeg" alt="" className="col-lg-4 zoom-stories" style={{marginBottom : 25}}/>
  <img src="/assets/images/stories/11.jpeg" alt="" className="col-lg-4 zoom-stories" style={{marginBottom : 25}}/>
  <img src="/assets/images/stories/12.jpeg" alt="" className="col-lg-4 zoom-stories" style={{marginBottom : 25}}/>
  <img src="/assets/images/stories/13.jpeg" alt="" className="col-lg-4 zoom-stories" style={{marginBottom : 25}}/>

</div>
                  
                    </div>
                </div>
                {/* <!-- Blog Wrapper End --> */}

            </div>
        </div>
        {/* <!-- Blog Section End -->. */}

    </div>
  );
}

export default Stories;
